import * as React from "react";

import { SecondaryPageTitle, SeriousTalk } from "../components/shared/secondary-page-elements";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import { H5 } from "../components/shared/headings";
import Layout from "../components/shared/layout";
import Navbar from "../components/navbar";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";

const CcpaRequest = () => {
  return (
    <Layout>
        <Seo title={"CCPA Request | Copilot"} />
        <DownloadModal/>
        <Navbar/>
        <Section>
            <Grid>
              <SecondaryPageTitle>CCPA Request</SecondaryPageTitle>
                <SeriousTalk>
                    <H5>Please email your request to <a href="mailto:support@copilot.money">support@copilot.money</a></H5>
                    <p>If you need help, don't hesitate to contact us through the app <span role="img" aria-label="raised hand">🙋‍♂️</span></p>
                </SeriousTalk>
            </Grid>
        </Section>
        <Footer/>
    </Layout>
  )
}

export default CcpaRequest
